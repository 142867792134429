<template>
  <app-list-view
    server-side
    app="revenue"
    model="revenuecategory"
    api-url="revenue/revenue-category/"
    :title="$t('menu.revenueCategory')"
    :createTo="{ name: 'revenueCategoryCreate' }"
    :editTo="{ name: 'revenueCategoryEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'revenueCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.revenueCategoryName'), value: 'name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.revenueType'),
          value: 'businessCategory',
          link: {
            prepareRouter: this.revenueTypeLink
          },
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'revenueCategory'
    })
  },
  methods: {
    revenueTypeLink(item) {
      return {
        name: 'revenueType',
        query: { revenue_categories: item.id }
      }
    }
  }
}
</script>
